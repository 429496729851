import React from "react";

const OpenImage = (props) => {
  let { key = '', classes = '', isMobile, setOpen, setDialogContent, img, alt, setStyle, rotated } = props

  return (
    <img loading={'lazy'} key={key !== '' ? key : ''} onClick={() => {
      setOpen(true)
        setDialogContent([<img className={`openScreenshot ${classes}`} style={{marginBottom: alt !== '' ? '15px' : '0px'}} src={isMobile && rotated ? rotated : img} alt={alt} data-rotate="90"/>, alt !== '' ? <div>{alt}</div> : <></>])
      if (isMobile) {
        setStyle({width: "85%", padding: "0"});
      } else {
        setStyle({width: "auto", height: "auto"});
      }
    }} className={`${classes} screenshot`} style={{}} src={img} alt={alt}/>
  )
}

export default OpenImage;